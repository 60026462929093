/* eslint-disable */
import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Navbar from "./Navbar";
import { useMediaQuery } from "react-responsive";
import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
  },
  welcomeBlock: {
    width: 500,
    height: 100,
    padding: 50,
  },
  root: {
    display: "flex",
    height: "100%",
  },
  footerGrid: {
    position: "relative",
  },
}));

export const Pages = (props) => {
  const classes = useStyles();
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  const isTabletDevice = useMediaQuery({
    query: "(max-device-width: 821px)",
  });

  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 1200px)",
  });

  const isBigScreen = useMediaQuery({
    query: "(min-device-width: 1201px )",
  });
  return (
    <div className={classes.root}>
      {isMobileDevice || isTabletDevice ? <SwipeableEdgeDrawer /> : <Navbar />}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.children}
        </Grid>
        <Grid item xs={12} className={classes.footerGrid}></Grid>
      </Grid>
    </div>
  );
};

export default Pages;
