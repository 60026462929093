import React, { useState, useEffect } from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import { useOktaAuth } from "@okta/okta-react";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNewRounded";
import WelcomeMessage from "../pages/welcome/Welcome";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/MenuRounded";
import IconButton from "@mui/material/IconButton";
import DashboardIcon from "@mui/icons-material/DashboardRounded";
import Tooltip from "@mui/material/Tooltip";
import HotelIcon from "@mui/icons-material/HotelRounded";
import Divider from "@mui/material/Divider";
const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function SwipeableEdgeDrawer(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState("");
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const logout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };
  if (!authState) {
    return null;
  }
  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };
  return (
    <>
      <div>
        {!authState.isPending && !authState.isAuthenticated && (
          <WelcomeMessage />
        )}
      </div>
      {authState.isAuthenticated && (
        <Root>
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(30% - ${drawerBleeding}px)`,
                overflow: "visible",
              },
            }}
          />

          <AppBar variant="fluid" style={{ backgroundColor: "#2F5E8C" }}>
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>

              <img
                src={process.env.PUBLIC_URL + "/CHicon.png"}
                width="35"
                height="35"
                className="d-inline-block align-top"
                alt=""
              ></img>
              <Typography
                className="navbar-brand p-2"
                style={{ fontSize: "18px" }}
              >
                CyncHealth{" "}
                <Typography style={{ fontSize: "12px" }}>
                  IOWA DASHBOARD
                </Typography>
              </Typography>
              <span className="example-fill-remaining-space"></span>

              <Button
                variant="outlined"
                color="inherit"
                id="logout-button"
                onClick={logout}
                endIcon={<PowerSettingsNewIcon />}
              >
                {" "}
                Logout
              </Button>
            </Toolbar>
          </AppBar>

          <SwipeableDrawer
            container={container}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: "visible",
                right: 0,
                left: 0,
              }}
            >
              <Puller />
              <Typography sx={{ p: 2, color: "text.secondary" }}>
                More Options
              </Typography>
            </StyledBox>
            <StyledBox
              sx={{
                px: 2,
                pb: 2,
                height: "100%",
                overflow: "auto",
              }}
            >
              <List>
                <Link
                  to="/menu"
                  style={{ textDecoration: "none", color: "grey" }}
                >
                  <ListItem button>
                    <Tooltip
                      disableFocusListener
                      title="Menu"
                      placement="top-end"
                      arrow
                    >
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText> Menu</ListItemText>
                  </ListItem>
                </Link>

                {handleGroupAuth("IDPH_IA_Bed_Occupancy_Users") ? (
                  <Link
                    to="/bedcapacity"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    <ListItem button>
                      <Tooltip
                        disableFocusListener
                        title="Live Bed Capacity"
                        placement="top-end"
                        arrow
                      >
                        <ListItemIcon>
                          <HotelIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText>
                        {" "}
                        Live Bed Capacity{" "}
                        <span className="livebadge pulsate ">Live</span>
                      </ListItemText>
                    </ListItem>
                  </Link>
                ) : (
                  ""
                )}

                <Divider />
              </List>
            </StyledBox>
          </SwipeableDrawer>
        </Root>
      )}
    </>
  );
}

export default SwipeableEdgeDrawer;
