import React, { useMemo, useState, useEffect } from "react";
import HotelRounded from "@mui/icons-material/HotelRounded";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import { useOktaAuth } from "@okta/okta-react";
import LinearProgress from "@mui/material/LinearProgress";
import NotFound from "../../Alerts/NotFound";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { List } from "@mui/material";
import BedCapacityGroup from "./BedCapacityGroup";
import { LoadingButton } from "@mui/lab";
import { RefreshOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-10px",
    fontWeight: "bold",
    fontSize: "30px",
  },
  icon: {
    color: "#2F5E8C",
    marginRight: "7px",
  },
});

export default function LiveBedCapacity() {
  const [bedsData, setBedsData] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const classes = useStyles();
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  const getData = async () => {
    setLoading(true);
    try {
      const url = process.env.REACT_APP_BASE_API + "/bedlivecapacity";
      // const url = "IA_Bed_Occupancy.json"
      const result = await axios
        .get(url, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data);
          const sortHospitalsByName = res.data.sort((a, b) => {
              return b.HOSPITAL_NAME.localeCompare(a.HOSPITAL_NAME)
        });
          setBedsData(sortHospitalsByName);
          setLoading(false);
          setButtonLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const updateTime = () => {
    var current = new Date();
    return current.toLocaleString();
  };

  useEffect(() => {
    getData();
  }, []);

  const refreshData = (e) => {
    setButtonLoading(true);
    getData();
  };

  const handleGroupAuth = (str) => {
    return userInfo && userInfo.indexOf(str) !== -1;
  };

  if (handleGroupAuth("IDPH_IA_Bed_Occupancy_Users")) {
    return (
      <div>
        {isMobileDevice ? (
          <div className="mt-3 pt-5">
            <List>
              <ListItem>
                <ListItemIcon>
                  <HotelRounded fontSize="medium" className={classes.icon} />
                </ListItemIcon>

                <ListItemText>
                  <div className="d-inline">
                    <span>Bed Capacity Dashboard</span>
                    <span className="livebadge pulsate ml-2">NRT</span>{" "}
                    <div style={{ fontSize: "small" }}>
                      {" "}
                      Last Updated : {updateTime()}
                    </div>
                    <LoadingButton
                      className="mt-2"
                      size="small"
                      onClick={(e) => refreshData(e)}
                      loading={buttonLoading}
                      loadingPosition="end"
                      variant="outlined"
                      endIcon={<RefreshOutlined />}
                    >
                      {" "}
                      Refresh
                    </LoadingButton>
                  </div>
                </ListItemText>
              </ListItem>
            </List>

            <div className="alert alert-light mt-2" style={{ width: "99%" }}>
              Authorized users agree information contained within the
              CyncHealth's Dashboards is confidential and agree to not
              disseminate such information contained therein.
            </div>
          </div>
        ) : (
          <div className="mt-5 pt-4">
            <List>
              <ListItem className={classes.root}>
                <ListItemIcon>
                  <HotelRounded fontSize="large" className={classes.icon} />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <span className={classes.text}>Bed Capacity Dashboard</span>
                  <div className="d-inline">
                    <span className="livebadge pulsate ml-2">NRT</span>{" "}
                    <span className=" ml-3">
                      {" "}
                      Last Updated : {updateTime()}
                    </span>
                    <LoadingButton
                      className="ml-2"
                      size="small"
                      onClick={(e) => refreshData(e)}
                      loading={buttonLoading}
                      loadingPosition="end"
                      variant="outlined"
                      endIcon={<RefreshOutlined />}
                    >
                      {" "}
                      Refresh
                    </LoadingButton>
                  </div>
                </ListItemText>
              </ListItem>
            </List>
            <div
              className="alert alert-light mt-2 mr-1"
              style={{ fontSize: "13px" }}
            >
              Authorized users agree information contained within the
              CyncHealth's Dashboards is confidential and agree to not
              disseminate such information contained therein.
            </div>
          </div>
        )}

        <Paper elevation={2} style={{ padding: "8px", height: "auto" }}>
          {loading ? <LinearProgress /> : <BedCapacityGroup beds={bedsData} />}
        </Paper>

        <div
          className="alert alert-light mt-4 mr-1"
          role="alert"
          style={{ fontSize: "12px" }}
        >
          <div style={{ marginTop: "10px" }}>
            This Dashboard is provided as a service to Authorized Users pursuant
            to applicable Data Sharing and Participation Agreements and in
            compliance with all applicable laws. Users are restricted from the
            following activities pursuant to the Agreements: make this Service
            available to any other person, entity, or business; copy,
            reverse-engineer, decompile, or disassemble the System or data
            contained herein; and modify or combine the System with any other
            software or services not provided or approved by CyncHealth.
          </div>

          <div style={{ marginTop: "10px" }}>
            In addition, this information may be confidential or proprietary and
            should be withheld from public record requests under Iowa Code §
            22.7 (specifically §§ 22.7(3) or (6))
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Licensed Beds</b>
            <br />
            The maximum number of beds for which a hospital holds a license to
            operate.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Physically Available Beds</b>
            <br />
            Physically available are licensed, physically set up, and available
            for use. These are beds regularly maintained in the hospital for the
            use of patients, which furnish accommodations with supporting
            services (such as food, laundry, and housekeeping). The number of
            physically available beds for a facility are configured by the
            facility’s designated Bed Manager on the Beds Roster Administrative
            Portal (which includes Room Number, Bed Type, Bed Number, Bed
            Status). If the facility’s designated Bed Manager has not configured
            the number of physically available beds within the application,
            reporting defaults to the number of licensed beds.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Staffed Beds</b>
            <br />
            Beds that are licensed and physically available for which staff is
            on hand to attend to the patient who occupies the bed. Includes both
            occupied and Vacant/Available beds with staff on hand to attend to
            the patient.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Unstaffed beds</b>
            <br />
            Beds that are licensed and physically available and have no current
            staff on hand to attend to a patient who would occupy the bed.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Occupied Beds</b>
            <br />
            Beds that are licensed, physically available, staffed, and occupied
            by a patient. This is often termed “census”. This near-real-time
            (NRT) bed occupancy data generates from the state Health Information
            Exchange (CyncHealth) and only contains those facilities that are
            actively sharing data with CyncHealth. Data is near-real time
            (within 30 minutes) with possible delays that may occur due to the
            time taken to ingest data from the facility until the time it can be
            displayed by CyncHealth. Beds occupied are obtained from Admission,
            Discharge, Transfer (ADT) events and can only reflect the data
            received from each facility by CyncHealth.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Vacant/Available Beds</b>
            <br />
            Beds that are vacant and to which patients can be transported
            immediately. These must include supporting space, equipment, medical
            material, ancillary and support services, and staff to operate under
            normal circumstances. These beds are licensed, physically available,
            and have staff on hand to attend to the patient who occupies the
            bed.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Unoccupied Beds</b>
            <br />
            Beds that are licensed, physically available but not occupied by a
            patient.
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Physical Capacity</b>
            <br />
            Ratio of Occupied Beds to Physically Available Beds
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>Staffed Capacity</b>
            <br />
            Ratio of Occupied beds to Staffed Beds   <br /> (Note: This calculation will
            not be accurate until after 4/1/24, when all facilities are expected
            to have entered/reported their baseline “staffed” beds.)
          </div>

          <div style={{ marginTop: "10px" }}>
            <b>https://loinc.org/92194-0</b>
          </div>
        </div>
      </div>
    );
  } else {
    return <NotFound />;
  }
}
