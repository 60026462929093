import React from "react";
import "./AppUsers.css";
import Tooltip from "@mui/material/Tooltip";
import { Controller, useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Checkbox from "@mui/material/Checkbox";
import { IconButton, LinearProgress, Switch } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, useTheme } from "@mui/material";
import { Alert, Snackbar } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Delete } from "@mui/icons-material";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

export default function AppUsers() {
  const { authState, oktaAuth } = useOktaAuth();
  const [emailSucceeded, setEmailSucceeded] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [userId, setUserId] = useState("");
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;
  useEffect(() => {
    getData();
    // const interval = setInterval(() => {
    //   getData();
    // }, 10000);
    // return () => clearInterval(interval);
  }, []);
  // get data
  const getData = async () => {
    const getDataUrl =
      process.env.REACT_APP_BASE_API_ADMIN + "/oktaroles?appidlocation=ia";
    const response = await fetch(getDataUrl, {
      headers: {
        Authorization: bearerToken,
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    setData(res);
    setUserId(res[0].user_id);
    setLoading(false);
  };
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeNotification = () => {
    setEmailSucceeded(false);
    setEmailFailed(false);
  };

  useEffect(reset, [emailSucceeded]);

  //add user to okta and app

  const addUserToOktaAndApp = async (values) => {
    setIsLoading(true);

    setEmailSucceeded(false);
    setEmailFailed(false);
    setLoading(true);
    const blob = values;
    const createUserUrl =
      process.env.REACT_APP_BASE_API_ADMIN +
      "/add-user-to-app?appidlocation=ia";
    const res = await fetch(createUserUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerToken,
      },
      body: JSON.stringify(blob),
    });
    const result = await res.json();
    setLoading(false);
    getData();
    setIsLoading(false);
    setEmailSucceeded(true);
  };
  //add
  const addUserToGroup = async (user_id, groupID, e) => {
    setLoading(true);
    e.stopPropagation();
    const addUserUrl =
      process.env.REACT_APP_BASE_API_ADMIN + "/adduser?appidlocation=ia";
    const blob = {
      user_id: user_id,
      groupID: groupID,
    };
    const res = await fetch(addUserUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerToken,
      },
      body: JSON.stringify(blob),
    });
    const result = await res.json();
    setLoading(false);
    setData(result);
  };

  //remove from app
  const removeUserFromApp = async (user_id, e) => {
    setLoading(true);
    e.stopPropagation();
    const deleteUserUrl =
      process.env.REACT_APP_BASE_API_ADMIN +
      "/remove-user-from-app?appidlocation=ia";
    const args = {
      id: user_id,
    };
    const res = await fetch(deleteUserUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerToken,
      },
      body: JSON.stringify(args),
    });
    const result = await res.json();
    setLoading(false);
    getData();
  };
  // remove
  const removeUserFromGroup = async (user_id, groupID, e) => {
    setLoading(true);
    e.stopPropagation();
    const deleteUserUrl =
      process.env.REACT_APP_BASE_API_ADMIN + "/removeuser?appidlocation=ia";
    const args = {
      user_id: user_id,
      groupID: groupID,
    };
    const res = await fetch(deleteUserUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: bearerToken,
      },
      body: JSON.stringify(args),
    });
    const result = await res.json();
    setLoading(false);
    setData(result);
  };
  // const handleClickOpen = () => {
  //   setOpen(true);
  // }
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const renderHeader = () => {
    let headerElement = [
      "User Info",
      "Iowa Bed Capacity",
      "Iowa Beds Roster",
    ];
    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const renderBody = () => {
    return (
      data &&
      data.map(({ user_id, user_name, user_email, Groups }) => {
        return (
          <tr key={user_id}>
            <td style={{ fontWeight: "bolder" }}>
              <div>
                <div style={{ display: "inline" }}>
                  {user_name} <br></br>
                  <span style={{ fontWeight: "lighter" }}>{user_email}</span>
                </div>
                <div style={{ display: "inline" }}>
                  <Tooltip
                    title="Remove From the App"
                    placement="left-start"
                    arrow
                  >
                    <IconButton
                      style={{ float: "right", marginTop: "-10px" }}
                      aria-label="delete"
                      onClick={(e) => removeUserFromApp(user_id, e)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </td>
            {Groups.slice(0, 8).map((group) => {
              return (
                <td>
                  {" "}
                  {group.groupMember == false ? (
                    <>
                      <Tooltip title="Add User" placement="right-start" arrow>
                        <Switch
                          inputProps={{ "aria-label": "add" }}
                          size="large"
                          checked={group.groupMember}
                          variant="contained"
                          className="button"
                          onClick={(e) =>
                            addUserToGroup(user_id, group.groupID, e)
                          }
                        >
                          {" "}
                        </Switch>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip
                        title="Remove from dashboard"
                        placement="right-start"
                        arrow
                      >
                        <Switch
                          inputProps={{ "aria-label": "remove" }}
                          checked={group.groupMember}
                          variant="contained"
                          size="large"
                          className="button"
                          onClick={(e) =>
                            removeUserFromGroup(user_id, group.groupID, e)
                          }
                        ></Switch>
                      </Tooltip>
                    </>
                  )}{" "}
                </td>
              );
            })}
          </tr>
        );
      })
    );
  };
  return (
    <div className="pt-4 mt-5">
      <div>
        <Snackbar
          open={emailSucceeded}
          autoHideDuration={8000}
          onClose={closeNotification}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={closeNotification}
            severity="success"
            variant="filled"
          >
            Success : Email has been sent to user!
          </Alert>
        </Snackbar>
        <Snackbar
          open={emailFailed}
          autoHideDuration={8000}
          onClose={closeNotification}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={closeNotification} severity="error" variant="filled">
            Error : Please Try again!
          </Alert>
        </Snackbar>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Create New User</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill out the form to create and assign user to this app
            </DialogContentText>
            <Box
              sx={{
                backgroundColor: "white",
                display: "flex",
                padding: "10px",
                flexDirection: "row",
              }}
            >
              <Box sx={{ display: "flex", flex: "auto" }}>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 0.1, width: "23ch" },
                    display: "inline-flex",
                    gap: "10px",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                  onSubmit={handleSubmit(addUserToOktaAndApp)}
                >
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <TextField
                          required
                          style={{ width: "calc(50% - 7.5px)" }}
                          variant="outlined"
                          {...field}
                          label={"First Name"}
                          inputProps={{
                            "aria-label": "first Name Label",
                          }}
                          {...register("firstName", { required: true })}
                        />
                      );
                    }}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <TextField
                          required
                          style={{ width: "calc(50% - 7.5px)" }}
                          {...field}
                          label={"Last Name "}
                          inputProps={{
                            "aria-label": "Last Name",
                          }}
                          {...register("lastName", { required: true })}
                        />
                      );
                    }}
                  />
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <TextField
                          required
                          helperText="Email will also act as Login ID."
                          type="email"
                          margin="normal"
                          style={{ width: "100%" }}
                          {...field}
                          label="Email"
                          inputProps={{
                            "aria-label": "Email Label",
                          }}
                          {...register("email", { required: true })}
                        />
                      );
                    }}
                  />
                  <Controller
                    name="mobilePhone"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          type="phone"
                          margin="normal"
                          style={{ width: "100%" }}
                          {...field}
                          label="Phone Number"
                          inputProps={{
                            "aria-label": "Phone Label",
                          }}
                          {...register("mobilePhone")}
                        />
                      );
                    }}
                  />

                  <Box>
                    <Button
                      disabled={!isValid || isLoading}
                      title="submit"
                      style={{ minWidth: "180px" }}
                      aria-label="submit"
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      <span>Create User</span>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
      <h2>
        <AdminPanelSettingsIcon fontSize="large" /> Admin Panel
      </h2>
      <p>
        Adding users to the mentioned dashboards will give access to the
        respective dashboard under IDPH IA application.
      </p>
      <Button
        style={{ marginBottom: "10px" }}
        variant="outlined"
        onClick={handleClickOpen}
        endIcon={<PersonAddIcon />}
      >
        Create New User{" "}
      </Button>
      {loading && <LinearProgress size={24} />}
      <table id="employee">
        <thead>
          <tr>{renderHeader()}</tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </table>
    </div>
  );
}
