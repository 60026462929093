import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { RocketLaunch, Update, TrendingUp } from "@mui/icons-material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

const formatLabel = (value, title) => {
  if (title === "Peak Update Time") {
    const startTime = dayjs(value).tz("America/Chicago").startOf("hour");
    const endTime = startTime.add(1, "hour");
    return `${startTime.format("hh:mm A")} - ${endTime.format("hh:mm A")} CST`;
  }
  return value;
};

const formatTooltip = (value, title) => {
  switch (title) {
    case "Update Frequency Per Day":
      return `Number of Updates Per Day: ${value}`;
    case "Peak Update Time":
      return `Number of Updates By Hour: ${value}`;
    case "Peak Update Day":
      return `Number of Updates by Week Day: ${value}`;
    default:
      return value;
  }
};

const DashboardCard = ({ icon, title, value, trend, chart }) => {
  const dataKeyMap = {
    "Update Frequency Per Day": "number_of_updates_by_day",
    "Peak Update Time": "number_of_updates_by_hour",
    "Peak Update Day": "number_of_updates_by_day_wk",
  };

  const labelKeyMap = {
    "Update Frequency Per Day": "lastupdatedon",
    "Peak Update Time": "max_last_update_timestamp",
    "Peak Update Day": "weekday_of_lastupdate",
  };

  return (
    <Card sx={{ borderRadius: "10px" }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar sx={{ bgcolor: trend }}>{icon}</Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="body2">{title}</Typography>
            <Typography fontWeight="bold" variant="h6">
              {value}
            </Typography>
          </Grid>
        </Grid>
        <Box mt={1}>
          <ResponsiveContainer width="100%" height={80}>
            <AreaChart data={chart}>
              <XAxis
                dataKey={labelKeyMap[title]}
                label={formatLabel(value, title)}
                hide
              />
              <Area
                type="monotone"
                dataKey={dataKeyMap[title]}
                stroke={trend}
                strokeWidth={2}
                fill={trend}
              />
              <Tooltip
                labelFormatter={(value) => formatLabel(value, title)}
                formatter={(value) => [formatTooltip(value, title)]}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

const CardChart = ({
  chartData,
  updateFrequency,
  peakUpdateTime,
  peakUpdateDay,
}) => (
  <Box pt={1}>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} md={4}>
        <DashboardCard
          icon={<Update style={{ fontSize: 20 }} />}
          title="Update Frequency Per Day"
          value={updateFrequency}
          trend="#83BE40"
          chart={chartData}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={4}>
        <DashboardCard
          icon={<RocketLaunch style={{ fontSize: 20 }} />}
          title="Peak Update Time"
          value={peakUpdateTime}
          trend="#F3AA1B"
          chart={chartData}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={4}>
        <DashboardCard
          icon={<TrendingUp style={{ fontSize: 20 }} />}
          title="Peak Update Day"
          value={peakUpdateDay}
          trend="#09AED9"
          chart={chartData}
        />
      </Grid>
    </Grid>
  </Box>
);
export default CardChart;
