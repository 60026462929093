/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  BrowserRouter,
} from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import config from "./config";
import CorsErrorModal from "./CorsErrorModal";
import Menu from "./components/pages/menu/index.jsx";
import Pages from "./components/layout/Pages";
import Profile from "./Profile";
import LiveBedCapacity from "./components/pages/livebedcapacity/LiveBedCapacity";
import NotFound from "./components/Alerts/NotFound";
import AdminAuth from "./components/adminPanel/AdminAuth";
import ContactUs from "./components/pages/Support/ContactUs";
import BedRoster from "./components/pages/bedsdataform/BedRoster";
import BedDataPanel from "./components/pages/bedsdataform/BedDataPanel";
import PageNotFound from "./components/Alerts/PageNotFound";
import BedRosterLogBook from "./components/adminPanel/BedRosterLogBook";

const oktaAuth = new OktaAuth(config.oidc);

const publicPages = [
  {
    path: "/login/callback",
    component: LoginCallback,
    layout: Pages,
  },
  {
    path: "/",
    component: LoginCallback,
    layout: Pages,
  },
  {
    path: "/notfound",
    component: NotFound,
    layout: Pages,
    viewPath: "",
    viewHeight: "auto",
  },
];

const securePages = [
  {
    path: "/bedcapacity",
    component: LiveBedCapacity,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  
  // {
  //   path: "/bedsdatapanel",
  //   component: BedDataPanel,
  //   layout: Pages,
  //   viewPath: "",
  //   viewHeight: 1000,
  // },
  {
    path: "/bedsroster",
    component: BedRoster,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/bedsroster-log-book",
    component: BedRosterLogBook ,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/menu",
    component: Menu,
    layout: Pages,
    viewPath: "",
    viewHeight: 1000,
  },
  {
    path: "/profile",
    component: Profile,
    layout: Pages,
    viewPath: "",
    viewHeight: "auto",
  },

  {
    path: "/admin",
    component: AdminAuth,
    layout: Pages,
    viewPath: "",
    viewHeight: "auto",
  },
  {
    path: "/contactus",
    component: ContactUs,
    layout: Pages,
    viewPath: "",
    viewHeight: "auto",
  },
];

const App = () => {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
        <Switch>
          {securePages.map(
            (
              {
                path,
                viewPath,
                viewHeight,
                component: Component,
                layout: Layout,
                groups,
              },
              index
            ) => {
              return (
                <SecureRoute
                  key={index}
                  path={path}
                  render={(props) => (
                    <Layout {...props}>
                      <Component
                        {...props}
                        viewPath={viewPath}
                        viewHeight={viewHeight}
                      />
                    </Layout>
                  )}
                />
              );
            }
          )}
          {publicPages.map(
            ({ path, component: Component, layout: Layout }, index) => (
              <Route
                key={index}
                path={path}
                exact
                render={(props) => (
                  <Layout {...props}>
                    <Component {...props} />
                  </Layout>
                )}
              />
            )
          )}

          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
    </Security>
  );
};

export default App;
