import React from "react";
import { Box, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

const PageNotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="90vh"
    >
      <img
        src={process.env.PUBLIC_URL + "/CHicon.png"}
        width="35"
        height="35"
        className="d-inline-block align-top"
        alt=""
      ></img>
      <Typography variant="h4">404 - Page Not Found</Typography>
    </Box>
  );
};

export default PageNotFound;
