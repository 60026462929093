import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import { AlarmOff, AlarmRounded, EventBusy } from "@mui/icons-material";

const DashboardCard = ({ icon, title, value, trend }) => (
  <Card sx={{ borderRadius: "10px" }}>
    <CardContent>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Avatar sx={{ bgcolor: trend }}>{icon}</Avatar>
        </Grid>
        <Grid item xs>
          <Typography variant="body2">{title}</Typography>
          <Typography variant="h4">{value}</Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const CardChart2 = ({
  updatedToday,
  updatedLast4hours,
  notUpdatedToday,
  notUpdatedLast4hours,
  selectedDateRange,
}) => {
  const isDateRangeSelected = selectedDateRange[0] !== null;

  return (
    <Box pt={1}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <DashboardCard
            icon={<TodayIcon style={{ fontSize: 20 }} />}
            title={
              isDateRangeSelected
                ? "Hospitals Updated"
                : "Hospitals Updated Today"
            }
            value={updatedToday}
            trend="#0E4D97"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DashboardCard
            icon={<AlarmRounded style={{ fontSize: 20 }} />}
            title="Hospitals Updated (Last 4 Hours)"
            value={updatedLast4hours}
            trend="#83BE40"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DashboardCard
            icon={<EventBusy style={{ fontSize: 20 }} />}
            title={
              isDateRangeSelected
                ? "Hospitals Not Updated"
                : "Hospitals Not Updated Today"
            }
            value={notUpdatedToday}
            trend="#F3AA1B"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DashboardCard
            icon={<AlarmOff style={{ fontSize: 20 }} />}
            title="Hospitals Not Updated (Last 4 Hours)"
            value={notUpdatedLast4hours}
            trend="#09AED9"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardChart2;
