import React from "react";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function NotFound({}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <div className="mt-5 pt-4">
      {loading ? (
        <CircularProgress style={{ margin: "200px", marginLeft: "300px" }} />
      ) : (
        <div>
          <h2> Access Restricted</h2>
          <p>Please reach out to your adminsitrator for access!!</p>
        </div>
      )}
    </div>
  );
}
