import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./polyfills";
import App from "./App";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
import Loader from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";
import { LicenseInfo } from "@mui/x-license";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2F5E8B",
    },
    background: {
      default: "#EEF2F6"
    },
    secondary: {
      main: "#F0F0F0",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_SECRET);
const root = ReactDOM.createRoot(document.getElementById("root"));

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    )
  );
};

/* eslint-disable react/jsx-filename-extension */
root.render(
  <Router>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>

    <LoadingIndicator />
  </Router>
);
registerServiceWorker();
