import React, { useMemo, useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import "./LiveBedCapacity.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import "./Table.css";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FaDownload, FaHospitalAlt } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Papa from "papaparse";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FormControl, InputLabel, Chip, Box } from "@mui/material";
import { useExportData } from "react-table-plugins";
import "jspdf-autotable";
import JsPDF from "jspdf";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useMediaQuery } from "react-responsive";
import { Select, MenuItem } from "@mui/material";
import { psychiatryAllBedsTabColumnNames } from "../../services/Constants";
import Checkbox from "@mui/material/Checkbox";
import logo from "../../../../public/CHLogo.png";
import HospitalInfoTooltip from "./HospitalDetails";

const getCellValue = (value) => {
  const value1 = parseInt(value);
if (value === 0) {
  return '0%'
}
  if (value === "-" || value == null) {
    return "-";
  } else if (Math.floor(value1) >= 80 && Math.floor(value1) <= 100) {
    const pValueER = Math.round(value1);
    return (
      <>
        <span style={{ backgroundColor: "#FF7F7F" }} className="badge">
          {pValueER}%{" "}
        </span>
      </>
    );
  } else if (Math.floor(value1) > 100) {
    const pValueER = Math.round(value1);
    return (
      <>
        {" "}
        <span style={{ backgroundColor: "#FF7F7F" }} className="badge">
          {pValueER}%{" "}
        </span>
        <span className="livebadge2 pulsate2">
          <WarningAmberIcon style={{ color: "white", fontSize: "10px" }} />
        </span>{" "}
      </>
    );
  } else if (Math.floor(value1) >= 60 && Math.floor(value1) < 80) {
    const pValueER = Math.round(value1);
    return (
      <>
        <span style={{ backgroundColor: "#F2E34C" }} className="badge">
          {pValueER}%{" "}
        </span>
      </>
    );
  } else {
    const pValueER = Math.round(value1);
    return (
      <>
        <span className="badge">{pValueER}% </span>
      </>
    );
  }
};


const calculateTotal = (rows, accessor) => {
  console.log(rows)
  return rows.reduce((sum, row) => {
    const value = row.values[accessor];
    if (value === null || value === "-") {
      return sum;
    }
    return sum + value;
  }, 0);
};

const useStyles = makeStyles({
  root: {
    height: "30px",
    color: "#2F5E8C",
  },
  text: {
    marginLeft: "-20px",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  sx: {
    "&& .Mui-selected": {
      backgroundColor: "pink",
    },
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}
function multiSelectFilter(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) =>
        filterValue.includes(String(row.original[columnIds]))
      );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    lineHeight: 2,
  },
}));

export default function PsychiatryAllBedsTable({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [columnsToExport, setColumnsToExport] = useState([]);
  const [openExportPopup, setOpenExportPopup] = useState(false);

  const isAllColumnsToExportSelected =
    psychiatryAllBedsTabColumnNames.length > 0 &&
    columnsToExport?.length === psychiatryAllBedsTabColumnNames.length;

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 480px)",
  });
  const isTabDevice = useMediaQuery({
    query: "(max-device-width: 1150px) ",
  });
  const classes = useStyles();

  const handleSelectedColumns = (event) => {
    const value = event.target.value || undefined;
    if (value[value.length - 1] === "all") {
      setColumnsToExport(
        columnsToExport?.length === psychiatryAllBedsTabColumnNames.length
          ? []
          : psychiatryAllBedsTabColumnNames
      );
      return;
    }
    setColumnsToExport(value);
  };

  const handleExportPopupClose = () => {
    setOpenExportPopup(false);
  };

  const keepCollectionProperties = (obj, keep) => {
    for (let i = 0; i < obj.length; i++) {
      for (let key in obj[i]) {
        if (keep.indexOf(key) === -1) {
          delete obj[i][key];
        }
      }
    }
    return obj;
  };

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    const selectedColumns = columns.filter((col) => {
      return columnsToExport.some((e) => col.Header.includes(e));
    });

    if (fileType === "csv") {
      // CSV example
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    }
    //PDF example

    if (fileType === "pdf") {
      const headerNames = selectedColumns.map((column) => column.exportValue);
      const objs = data.map((x) => ({
        "Hospital Name": x[0],
        "Hospital Type": x[1],
        NMDS: x[2],
        "Hospital City": x[3],
        "Hospital County": x[4],
        "Hospital Zip": x[5],
        "AHA Number": x[6],
        RMCC: x[7],
        HCC: x[8],
        "Total Staffed Beds*": x[9],
        "Total Occupied Beds": x[10],
        "Total Beds Available": x[11],
        "Percentage Occupied": x[12],
      }));

      const final = keepCollectionProperties(objs, headerNames);
      const finalPdfData = final.map((e) => Object.values(e));

      const doc = new JsPDF("landscape");
      doc.addImage(logo, "PNG", 16, 4, 25, 7);
      doc.setTextColor(110);
      doc.setFontSize(10);
      doc.text(230, 10, "IDPH Bed Occupancy Report");
      doc.setFontSize(8);

      doc.text(
        10,
        18,
        "Disclaimer : This Dashboard is provided as a service to Authorized Users pursuant to applicable Data Sharing and Participation Agreements and in compliance with all applicable laws. Users are restricted from the following "
      );
      doc.text(
        10,
        21,
        "activities pursuant to the Agreements: make this Service available to any other person, entity or business; copy, reverse-engineer, decompile, or disassemble the System or data contained herein; and modify or combine the"
      );
      doc.text(
        10,
        24,
        "System with any other software or services not provided or approved by CyncHealth."
      );

      doc.text(
        10,
        29,
        "Data shown calculates beds occupied by total beds minus available beds. This near-real-time occupancy data comes from the state HIE (CyncHealth) and so only contains those facilities who are actively sharing data with"
      );
      doc.text(
        10,
        32,
        "CyncHealth. Bed types are obtained from the interface message and can only reflect the data as shared by the facility."
      );
      const date = new Date();
      doc.text("Reported Time: " + date, 185, 190);
      doc.autoTable({
        head: [headerNames],
        body: finalPdfData,
        startY: 35,

        styles: {
          theme: "grid",
          minCellHeight: 15,
          top: 30,

          halign: "left",
          valign: "top",
          fontSize: 6,
          overflow: "linebreak",
        },
      });

      doc.save(`${fileName}.pdf`);

      return false;
    }

    // Other formats goes here
    return false;
  }
  const columns = useMemo(
    () => [
      {
        // first group - TV Show
        Header: "Hospital Name",
        accessor: "HOSPITAL_NAME",
        disableFilters: true,
        Footer: "Total",
        Cell: (row) => {
          return (
            <div>
              <HospitalInfoTooltip row={row} />
            </div>
          );
        },
        // First group columns
      },
      {
        Header: "Hospital Type",
        accessor: "HOSPITAL_TYPE",
        disableFilters: true,
        Footer: "",
      },
      {
        Header: "NMDS",
        accessor: "NMDS",
        disableFilters: true,
        Footer: "",
      },
      {
        Header: "Hospital City",
        accessor: "HQ_CITY",
        disableFilters: true,
        Footer: "",
      },
      {
        Header: "Hospital County",
        accessor: "COUNTY_NAME",
        disableFilters: true,
        filter: multiSelectFilter,
        show: false,
        Footer: "",
      },
      {
        Header: "Hospital Zip",
        accessor: "HQ_ZIP_CODE",
        disableFilters: true,
        Footer: "",
      },
      {
        Header: "AHA Number",
        accessor: "Facility_AHA_ID",
        disableFilters: true,
        Footer: "",
      },
      {
        Header: "RMCC",
        accessor: "RMCC",
        disableFilters: true,
        filter: multiSelectFilter,
        show: false,
        Footer: "",
      },
      {
        Header: "HCC",
        accessor: "HCC",
        disableFilters: true,
        filter: multiSelectFilter,
        show: false,
        Footer: "",
      },
      {
        // first group - TV Show
        Header: "Psychiatric Beds",
        tipText: `Inpatient beds used for child, adult or geriatric psychiatric care. Beds displaying here have been
        designated on the bed roster as ‘Psych Geriatric Beds’ or ‘Psych Child Beds’ or ‘Psych Adult Beds’. Does
        not include Adult or Pediatric Medical/Surgical, Adult ICU or Pediatric (PICU) beds.`,
        Footer: "",
        // First group columns
        columns: [
          {
            Header: "Physically Available",
            accessor: "Psych_Licensed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id)
          },
          {
            Header: "Staffed",
            accessor: "Psych_Staffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id)
          },
          {
            Header: "Unstaffed",
            accessor: "Psych_Unstaffed_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id)
          },
          {
            Header: "Occupied",
            accessor: "Psych_Occupied_Beds",
            
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id)
          },
          {
            Header: "Vacant/ Available",
            accessor: "Psych_Available_Beds",
            disableFilters: true,
            Footer: (info) => calculateTotal(info.rows, info.column.id)
          },
          {
            Header: "Physical Capacity",
            tipText: `Ratio of Occupied Beds to Physically Available Beds`,
            accessor: "Percentage_Psych_Occupied",
            disableFilters: true,
            Cell: ({ cell : {value}}) => getCellValue(value),
            Footer: "",
          },
          {
            Header: "Staffed Capacity",
            tipText: `Ratio of Occupied Beds to Staffed Bed`,
            accessor: "Staffed_Percentage_Psych_Occupied",
            disableFilters: true,
            Cell: ({ cell : {value}}) => getCellValue(value),
            Footer: "",
          },
        ],
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setFilter,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [{  desc: true }],
      },
      getExportFileBlob,
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    useExportData
  );

  return (
    <div>
      <div>
        <h6 className="card-header bg-light">
          <ListItem className={classes.root}>
            <ListItemIcon>
              <FaHospitalAlt size={25} className={classes.icon} />
            </ListItemIcon>
            <ListItemText disableTypography className={classes.text}>
              OCCUPANCY BY HOSPITAL
            </ListItemText>
            <div>
              <Dialog open={openExportPopup} onClose={handleExportPopupClose}>
                <DialogTitle>PDF Exporting Options</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please select all columns you want to export.
                  </DialogContentText>
                  <FormControl style={{ width: "90%", margin: 3 }}>
                    <InputLabel id="demo-multiple-chip-label">
                      Columns
                    </InputLabel>

                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple={true}
                      value={columnsToExport || []}
                      MenuProps={MenuProps}
                      onChange={(event) => handleSelectedColumns(event)}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      <MenuItem value="all">
                        <Checkbox checked={isAllColumnsToExportSelected} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {psychiatryAllBedsTabColumnNames.map((name) => {
                        return (
                          <MenuItem key={name} value={name}>
                            {" "}
                            <Checkbox
                              checked={columnsToExport.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />{" "}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      exportData("pdf", true);
                    }}
                  >
                    Export All
                  </Button>
                  <Button
                    onClick={() => {
                      exportData("pdf", false);
                    }}
                  >
                    Export Current View
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </ListItem>{" "}
        </h6>
      </div>
      <div className={isTabDevice ? "tableWrap" : ""}>
        <table className="specialty" {...getTableProps()}>
          <thead id={isTabDevice ? "" : isMobileDevice ? "" : "sticky-thc"}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    id="new-tooltip"
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }
                  >
                    {column.render("Header")}
                    {headerGroup.headers[index].tipText && (
                      <span>{headerGroup.headers[index].tipText}</span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              if (row.values.Total_Licensed_Psych_Beds != "") {
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              } else {
                return null;
              }
            })}
            {footerGroups.map((group) => (
              <tr id="footer-row" {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
