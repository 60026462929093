// AttestationButton.js
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

const AttestationButton = ({
  apiEndpoint,
  payload,
  dialogMessage,
  onConfirm,
}) => {
  const [open, setOpen] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      const response = await axios.put(apiEndpoint, payload, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/json",
        },
      });

      // Axios automatically parses the JSON response
      const data = response.data;

      // Call onConfirm if it's provided
      onConfirm && onConfirm(data);
    } catch (error) {
      // Axios gives more detailed error information
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="success"
        sx={{ marginRight: "5px" }}
        startIcon={<CheckCircleOutlineIcon />}
        onClick={handleClickOpen}
      >
        Attestation
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Acknowledge Roster Attestation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMessage ||
              "By confirming, you acknowledge that the bed roster is accurate for today. Your name and timestamp will be recorded. Do you wish to confirm?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="error"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AttestationButton;
