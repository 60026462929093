import React from "react";
import "./Welcome.css";
export default function Slide() {
  return (
    <div>
      <div
        style={{
          zIndex: "1",
          backgroundColor: "lightblue",
        }}
        id="carouselExampleInterval"
        className="carousel slide"
        data-ride="carousel"
        data-interval="20000"
      >
        <div className="carousel-inner">
          <div className="carousel-item active" data-interval="20000">
            <div className="gradBack"> 
            <div className="carousel-caption" style={{ top: "270px" }}>
              <h4>
              IDPH Recognizes June as Alzheimer’s and Brain Awareness Month to Promote Cognitive Health
              </h4>

              <div style={{ fontStyle: "italic", fontSize: "12px" }}>
                {" "}
                Apr 26, 2020{" "}
              </div>

              <p>
              The Iowa Department of Public Health is recognizing June as Alzheimer’s and Brain Awareness Month. More than 66,000 Iowans have been diagnosed with Alzheimer’s disease
              </p>
              <a
                className="badge badge-warning"
                href="https://idph.iowa.gov/News/ArtMID/646/ArticleID/158462/IDPH-Recognizes-June-as-Alzheimer%e2%80%99s-and-Brain-Awareness-Month-to-Promote-Cognitive-Health"
                target="_blank"
              >
                read more
              </a>
            </div>
            </div>
          </div>
          <div className="carousel-item" data-interval="20000">
            <div className="gradBack"> 
            <div className="carousel-caption" style={{ top: "270px" }}>
              <h4>
May is Mental Health Awareness Month</h4>
              <div style={{ fontStyle: "italic", fontSize: "12px" }}>
                {" "}
                Mar 20, 2020{" "}
              </div>

              <p>
                {" "}
                May is Mental Health Awareness Month and The Iowa Departments of Public Health and Human Services, soon to be the Department of Health and Human Services (HHS)
              </p>
              <a
                className="badge badge-warning"
                href="https://idph.iowa.gov/News/ArtMID/646/ArticleID/158460/May-is-Mental-Health-Awareness-Month"
                target="_blank"
              >
                read more
              </a>
            </div>{" "}
            </div>
          </div>
          <div className="carousel-item" data-interval="20000">
            <div className="gradBack"> 
            <div className="carousel-caption" style={{ top: "270px" }}>
              <h3>
                
Formula Shortage Update from the Departments of Public Health and Human Services
              </h3>
              <div style={{ fontStyle: "italic", fontSize: "12px" }}>
                Mar 18, 2020
              </div>

              <p>
              In Iowa and nationwide, infant formula is in short supply. The Iowa Departments of Public Health and Human Services are working to provide information and resources to Iowans who are impacted by the shortage.
              </p>
              <a
                className="badge badge-warning"
                href="https://idph.iowa.gov/News/ArtMID/646/ArticleID/158461/Formula-Shortage-Update-from-the-Departments-of-Public-Health-and-Human-Services"
                target="_blank"
              >
                read more
              </a>
            </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleInterval"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleInterval"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
}
