import React, { useState, useRef, useEffect } from "react";
import HotelIcon from "@mui/icons-material/HotelRounded";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";
import "./LiveBedCapacity.css";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import { List } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
const useStyles = makeStyles({
  root: {
    height: "20px",
    color: "black",
  },
  text: {
    marginLeft: "-20px",
    color: "#2F5E8C",
    fontStyle: "bold",
  },
  icon: {
    color: "#2F5E8C",
    marginLeft: "-10px",
    fontSize: "25px",
  },
});

function TotalAvailableBeds({ beds, type, type2, tab }) {
  const classes = useStyles();
  const [totalStaffedBeds, setTotalStaffedBeds] = useState("");
  const [totalOccupiedBeds, setTotalOccupiedBeds] = useState("");
  const [totalErBeds, setTotalErBeds] = useState("");
  const [totalErOccupiedBeds, setTotalErOccupiedBeds] = useState("");
  const [totalBedsAvailable, setTotalBedsAvailable] = useState("");
  const [totalPhysicalPercentage, setTotalPhysicalPercentage] = useState("");
  const [totalStaffedPercentage, setTotalStaffedPercentage] = useState("");
  const [totalPhysicallyAvailableBeds, setTotalPhysicallyAvailableBeds] =
    useState("");
  const [totalUnstaffedBeds, setTotalUnstaffedBeds] = useState("");
  const calculate = (beds, type, type2) => {
    // const sumOfErdBeds =
    //   beds &&
    //   beds.reduce(function (accumulator, object) {
    //     const erBeds = object[`Total_ER_Beds`];
    //     if (erBeds != null && erBeds !== "-") {
    //       return accumulator + parseInt(erBeds);
    //     } else {
    //       return accumulator;
    //     }
    //   }, 0);

    // setTotalErBeds(sumOfErdBeds);

    // const sumOfErOccupiedBeds =
    //   beds &&
    //   beds.reduce(function (accumulator, object) {
    //     const erBeds = object[`${type2}Occupied_Beds`];
    //     if (erBeds != null && erBeds !== "-") {
    //       return accumulator + parseInt(erBeds);
    //     } else {
    //       return accumulator;
    //     }
    //   }, 0);

    // setTotalErOccupiedBeds(sumOfErOccupiedBeds);
    const sumOfPhysicallyAvailableBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        const licensedBeds = object[`${type}Licensed_Beds`];
        if (licensedBeds != null && licensedBeds !== "-") {
          return accumulator + parseInt(licensedBeds);
        } else {
          return accumulator;
        }
      }, 0);
    // console.log(sumOfStaffedBeds, "sum1");
    setTotalPhysicallyAvailableBeds(sumOfPhysicallyAvailableBeds);

    const sumOfTotalStaffedBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        const licensedBeds = object[`${type}Staffed_Beds`];
        if (licensedBeds != null && licensedBeds !== "-") {
          return accumulator + parseInt(licensedBeds);
        } else {
          return accumulator;
        }
      }, 0);
    // console.log(sumOfStaffedBeds, "sum1");
    setTotalStaffedBeds(sumOfTotalStaffedBeds);

    const sumOfTotalUnstaffedBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        const licensedBeds = object[`${type}Unstaffed_Beds`];
        if (licensedBeds != null && licensedBeds !== "-") {
          return accumulator + parseInt(licensedBeds);
        } else {
          return accumulator;
        }
      }, 0);
    // console.log(sumOfStaffedBeds, "sum1");
    setTotalUnstaffedBeds(sumOfTotalUnstaffedBeds);

    const sumOfOccupiedBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        const occupiedBeds = object[`${type}Occupied_Beds`];
        if (occupiedBeds != null && occupiedBeds !== "-") {
          return accumulator + parseInt(occupiedBeds);
        } else {
          return accumulator;
        }
      }, 0);

    setTotalOccupiedBeds(sumOfOccupiedBeds);

    const sumOfAvailableBeds =
      beds &&
      beds.reduce(function (accumulator, object) {
        const availableBeds = object[`${type}Available_Beds`];
        if (availableBeds != null && availableBeds !== "-") {
          return accumulator + parseInt(availableBeds);
        } else {
          return accumulator;
        }
      }, 0);

    setTotalBedsAvailable(sumOfAvailableBeds);
    const percentageOfPhysicallySums =
      (sumOfOccupiedBeds / sumOfPhysicallyAvailableBeds) * 100;
    setTotalPhysicalPercentage(percentageOfPhysicallySums);

    const percentageOfStaffedSums =
      (sumOfOccupiedBeds / sumOfTotalStaffedBeds) * 100;
    setTotalStaffedPercentage(percentageOfStaffedSums);
  };

  useEffect(() => {
    calculate(beds, type, type2);
  }, [beds, type, type2]);

  const percentageValueEditor = (value) => {
    console.log(value);
    const value1 = value;
    if (value1 == " " || value1 == 0 || value == Infinity) {
      return "0%";
    } else if (Math.floor(value1) >= 80 && Math.floor(value1) <= 100) {
      const pValueER = Math.round(value1);
      return (
        <>
          <span
            style={{ backgroundColor: "#FF7F7F" }}
            className="badgeTotalNumber"
          >
            {pValueER}%{" "}
          </span>
        </>
      );
    } else if (Math.floor(value1) > 100) {
      const pValueER = Math.round(value1);
      return (
        <>
          {" "}
          <span
            style={{ backgroundColor: "#FF7F7F" }}
            className="badgeTotalNumber"
          >
            {pValueER}%{" "}
          </span>
          <span className="livebadgeTotal pulsate2">
            <WarningAmberOutlined
              style={{ color: "white", fontSize: "15px" }}
            />
          </span>{" "}
        </>
      );
    } else if (Math.floor(value1) >= 60 && Math.floor(value1) < 80) {
      const pValueER = Math.round(value1);
      return (
        <>
          <span
            style={{ backgroundColor: "#F2E34C" }}
            className="badgeTotalNumber"
          >
            {pValueER}%{" "}
          </span>
        </>
      );
    } else {
      const pValueER = value1.toFixed(2);
      return (
        <>
          <span className="badgeTotalNumber">{pValueER}% </span>
        </>
      );
    }
  };

  return (
    <div className="sticky-thc-totals">
      {/* Total beds card */}
      <h6 className="card-header bg-light">
        <List>
          <ListItem className={classes.root}>
            <ListItemIcon>
              <HotelIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText disableTypography className={classes.text}>
              TOTAL AVAILABLE BEDS
            </ListItemText>
          </ListItem>{" "}
        </List>
      </h6>{" "}
      <div className="row no-gutters pt-3 pb-4">
        <div className="col-sm">
          {/* <Example/> */}
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {totalPhysicallyAvailableBeds}
              </h2>
              <span className="card-text">Physically Available </span>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {totalStaffedBeds}
              </h2>
              <span className="card-text">Staffed</span>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {totalUnstaffedBeds}
              </h2>
              <span className="card-text ">Unstaffed</span>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {totalOccupiedBeds}
              </h2>
              <span className="card-text">Occupied</span>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {totalBedsAvailable}
              </h2>
              <span className="card-text">Vacant/Available</span>
            </div>
          </div>
        </div>{" "}
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {percentageValueEditor(totalPhysicalPercentage)}
              </h2>
              <span className="card-text">Physical Capacity</span>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title font-weight-bold">
                {" "}
                {percentageValueEditor(totalStaffedPercentage)}
              </h2>
              <span className="card-text">
                Staffed Capacity
                <Tooltip
                  placement="left-start"
                  title={
                    <React.Fragment>
                      Staffed Capacity = Ratio of Occupied beds to Staffed Beds.
                      Note: This calculation will not be accurate until after
                      4/1/24, when all facilities are expected to have
                      entered/reported their baseline “staffed” beds.
                    </React.Fragment>
                  }
                >
                  <span id="staffed-hover">*</span>
                </Tooltip>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalAvailableBeds;
