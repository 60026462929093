import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOktaAuth } from "@okta/okta-react";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  useTheme,
  Snackbar,
  Alert,
} from "@mui/material";

export default function ContactUs() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSucceeded, setEmailSucceeded] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const reasons = [
    {
      id: "applicationAccess",
      displayName: "Unable to access the application",
    },
    {
      id: "dashboardAccess",
      displayName: "Unable to seee dashboard content ",
    },
    {
      id: "dataQuality",
      displayName: "Ouestions About Data",
    },
    {
      id: "pageNotFound",
      displayName: "Getting a Page Not Found (404) Error",
    },
  ];
  const clearForm = () => {
    reset({
      subject: reasons[0].displayName,
      firstname: userInfo?.given_name || "",
      lastname: userInfo?.family_name || "",
      email: userInfo?.email || "",
      description: "",
    });
  };
  useEffect(() => {
    if (emailSucceeded) {
      clearForm();
    }
  }, [emailSucceeded]);
  const submitEmail = async (values) => {
    setIsLoading(true);
    setEmailSucceeded(false);
    setEmailFailed(false);
    // const userId = userInfo.sub;
    // const blob = values;
    // const createUserUrl =
    //   process.env.REACT_APP_SUPPORT_API + `/${userId}` + `?appidlocation=ia`;
    // const response = await fetch(createUserUrl, {
    //   method: "POST",
    //   headers: {
    //     Authorization: bearerToken,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(blob),
    // });

    const { subject, firstname, lastname, email, description } = values;
    const emailBody = `Subject: ${subject}\n\nFirst Name: ${firstname}\n\nLast Name: ${lastname}\n\nEmail: ${email}\n\nDescription: ${description}`;
    const emailSubject = "IA Region7 Dashboard";

    const mailtoUri = `mailto:support@cynchealth.org?subject=${encodeURIComponent(
      emailSubject + " - " + subject
    )}&body=${encodeURIComponent(emailBody)}`;

    // Create a temporary <a> element to trigger the email client
    const mailtoLink = document.createElement("a");
    mailtoLink.href = mailtoUri;
    mailtoLink.click();

    setIsLoading(false);
    setEmailSucceeded(true);
  };

  const closeNotification = () => {
    setEmailSucceeded(false);
    setEmailFailed(false);
  };

  return (
    <div className="mt-4 pt-5">
      <Snackbar
        open={emailSucceeded}
        autoHideDuration={8000}
        onClose={closeNotification}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={closeNotification} severity="info" variant="filled">
          Info : Please click send from your drafted email!
        </Alert>
      </Snackbar>
      <Snackbar
        open={emailFailed}
        autoHideDuration={8000}
        onClose={closeNotification}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={closeNotification} severity="error" variant="filled">
          Error : Please Try again!
        </Alert>
      </Snackbar>
      <h1>
        {" "}
        <ContactMailIcon style={{ margin: "10px", fontSize: "40px" }} />
        Contact Us
      </h1>
      <p style={{ paddingLeft: "10px" }}>
        {" "}
        Send us a message, we will get back to you soon!{" "}
      </p>
      {userInfo && (
        <div>
          <Box
            sx={{
              backgroundColor: "white",
              display: "flex",
              padding: "30px",
              flexDirection: "row",
            }}
          >
            <Box sx={{ display: "flex", flex: "auto" }}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 0.1, width: "23ch" },
                  display: "inline-flex",
                  gap: "10px",
                  width: "100%",
                  flexWrap: "wrap",
                }}
                onSubmit={handleSubmit(submitEmail)}
              >
                <Controller
                  name="subject"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <TextField
                        select
                        required
                        style={{ width: "100%" }}
                        defaultValue={reasons[0].displayName}
                        {...field}
                        label={
                          "Please select the type of issue you are experiencing:"
                        }
                        inputProps={{
                          "aria-label": "reason",
                        }}
                        {...register("subject", { required: true })}
                      >
                        {reasons.map((reason, index) => (
                          <MenuItem key={index} value={reason.displayName}>
                            {reason.displayName}
                          </MenuItem>
                        ))}
                      </TextField>
                    );
                  }}
                />
                <Controller
                  name="firstname"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <TextField
                        required
                        style={{ width: "calc(50% - 7.5px)" }}
                        variant="outlined"
                        defaultValue={userInfo.given_name}
                        {...field}
                        label={"First Name"}
                        inputProps={{
                          "aria-label": "first Name Label",
                        }}
                        {...register("firstname", { required: true })}
                      />
                    );
                  }}
                />
                <Controller
                  name="lastname"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <TextField
                        required
                        style={{ width: "calc(50% - 7.5px)" }}
                        defaultValue={userInfo.family_name}
                        {...field}
                        label={"Last Name"}
                        inputProps={{
                          "aria-label": "Last Name",
                        }}
                        {...register("lastname", { required: true })}
                      />
                    );
                  }}
                />
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <TextField
                        required
                        type="email"
                        margin="normal"
                        style={{ width: "100%" }}
                        defaultValue={userInfo.email}
                        {...field}
                        label="Email"
                        inputProps={{
                          "aria-label": "email Label",
                        }}
                        {...register("email", { required: true })}
                      />
                    );
                  }}
                />

                <Controller
                  name="description"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <TextField
                        required
                        multiline
                        style={{ width: "100%" }}
                        rows={6}
                        {...field}
                        label={
                          "Please describe the issue or question that you have:"
                        }
                        inputProps={{
                          "aria-label": "messageLabel",
                        }}
                        {...register("description", { required: true })}
                      />
                    );
                  }}
                />
                <Box>
                  <Button
                    disabled={!isValid || isLoading}
                    title="submit"
                    style={{ minWidth: "180px" }}
                    aria-label="submit"
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    <span>Submit</span>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
}
