import { Button } from "react-bootstrap";
import UpdateUserProfile from "./UpdateUserProfile";
import "./UserProfile.css";

import React, { Component } from "react";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { profile: [], addModalShow: false };
  }

  componentDidMount() {
    this.refreshUserProfile();
  }
  refreshUserProfile() {
    const userId = this.props.userId.sub;
    const token = this.props.authToken;
    const getDataUrl =
      process.env.REACT_APP_BASE_API_ADMIN + `/editprofile/${userId}`;
    fetch(getDataUrl, {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ profile: data.profile });
      });
  }
  render() {
    const { profile } = this.state;
    let addModalClose = () => {
      this.setState({ addModalShow: false });
      this.refreshUserProfile();
    };

    return (
      <div>
        <UpdateUserProfile
          token={this.props.authToken}
          userData={this.props.userId}
          show={this.state.addModalShow}
          onHide={addModalClose}
        />
        <div>
          <div className="row container d-flex ">
            <div className="col-xl-6 col-xl-12">
              <div className="card3 user-card-full">
                <div className="row m-l-0 m-r-0">
                  <div className="col-sm-4 bg-c-lite-green user-profile">
                    <div className="card-block text-center text-white">
                      <div className="m-b-25">
                        <img
                          src="https://img.icons8.com/pastel-glyph/64/000000/user-settings.png"
                          alt="User Logo"
                        />{" "}
                      </div>
                      <h6 className="f-w-600">
                        {profile.firstName} {profile.lastName}
                      </h6>
                      <Button
                        variant="outline-light"
                        onClick={() => this.setState({ addModalShow: true })}
                        size="sm"
                      >
                        Edit Profile
                      </Button>
                      {/* <p>Web Designer</p> <i className=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i> */}
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="card-block">
                      <h6 className="m-b-20 p-b-5 b-b-default f-w-600">
                        Personal Information
                      </h6>
                      <div className="row">
                        <div className="col-sm-4">
                          <p className="m-b-10 f-w-600">First Name</p>
                          <h6
                            style={{ color: "#6d6d6d" }}
                            className="text f-w-400"
                          >
                            {profile.firstName}
                          </h6>
                        </div>
                        <div className="col-sm-4">
                          <p className="m-b-10 f-w-600">Last Name</p>
                          <h6
                            style={{ color: "#6d6d6d" }}
                            className="text f-w-400"
                          >
                            {profile.lastName}
                          </h6>
                        </div>
                        <div className="col-sm-4">
                          <p className="m-b-10 f-w-600"> Phone </p>
                          <h6
                            style={{ color: "#6d6d6d" }}
                            className="text f-w-400"
                          >
                            {profile.phone !== null
                              ? profile.mobilePhone
                              : " - "}
                          </h6>
                        </div>
                      </div>
                      <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">
                        Login Info
                      </h6>
                      <div className="row">
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600">Login ID</p>
                          <h6
                            style={{ color: "#6d6d6d" }}
                            className="text f-w-400"
                          >
                            {profile.login}
                          </h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600">Email</p>
                          <h6
                            style={{ color: "#6d6d6d" }}
                            className="text f-w-400"
                          >
                            {profile.email}
                          </h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600"> Provider</p>
                          <h6
                            style={{ color: "#6d6d6d" }}
                            className="text f-w-400"
                          >
                            {" "}
                            -
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
