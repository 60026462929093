import React from "react";
import { useEffect, useState } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import {
  Avatar,
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ReceiptLongOutlined } from "@mui/icons-material";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import NotFound from "../Alerts/NotFound";
import { CalendarMonth } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/en";
import CardChart from "./CardChart";
import CardChart2 from "./CardChart2";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.locale("en");
// Set default timezone to CST
dayjs.tz.setDefault("America/Chicago");

const useStyles = makeStyles({
  root: {
    height: "20px",
    color: "black",
    display: "flex",
  },
  text: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#212121",
  },
  textheaders: {
    marginLeft: "-15px",
    fontWeight: "bold",
    color: "#2F5E8C",
  },
  icon: {
    color: "white",
  },
});

const shortcutsItems = [
  {
    label: "Last 30 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(29, "day"), today];
    },
  },
  {
    label: "Last 14 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(13, "day"), today];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(6, "day"), today];
    },
  },
];

const BedRosterLogBook = () => {
  const classes = useStyles();
  const [userGroups, setUserGroups] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState([]);
  const [totalFacilities, setTotalFacilities] = useState(null);
  const [updatedToday, setUpdatedToday] = useState(null);
  const [updatedLast4hours, setUpdatedLast4hours] = useState(null);
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;
  const [selectedRange, setSelectedRange] = useState([null, null]); //  date range state for picker
  const [logsDataDateRange, setLogsDataDateRange] = useState([null, null]); // date range state for data
  const [numberOfDaysSelected, setNumberOfDaysSelected] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [updateFrequencyMetrics, setUpdateFrequencyMetrics] = useState(null);
  const [peakUpdateTimeMetrics, setPeakUpdateTimeMetrics] = useState(null);
  const [updatePatternMetrics, setUpdatePatternMetrics] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserGroups(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    getDataForBeds();
  }, [logsDataDateRange]);

  const handleDateRangeChange = (newValue) => {
    const formattedDates = newValue.map((date, index) => {
      return date
        ? index === 0
          ? dayjs(date).startOf("day")
          : dayjs(date).endOf("day")
        : null;
    });
    const [startDate, endDate] = formattedDates;

    if (startDate && endDate) {
      setSelectedRange(formattedDates);
      setLogsDataDateRange(formattedDates);
      const numberOfDays = endDate.diff(startDate, "days") + 1; // Add 1 to include both start and end dates
      setNumberOfDaysSelected(numberOfDays);
    } else {
      setNumberOfDaysSelected(1);
      setLogsDataDateRange(formattedDates);
    }
  };

  function countUpdatedRecords(arrayOfObjects, selectedRange) {
    console.log(selectedRange);
    const today = dayjs().startOf("day");
    const fourHoursAgo = dayjs().subtract(4, "hour");

    let countToday = 0;
    let countLast4Hours = 0;
    let countInRange = 0;

    let startDate = null;
    let endDate = null;

    if (selectedRange && selectedRange[0] && selectedRange[1]) {
      startDate = dayjs(selectedRange[0]).startOf("day");
      endDate = dayjs(selectedRange[1]).endOf("day");
    }

    arrayOfObjects.forEach((obj) => {
      const lastUpdatedOn = dayjs(obj.max_last_update_timestamp);

      if (startDate && endDate) {
        // Check if lastUpdatedOn is within the given date range
        if (lastUpdatedOn.isBetween(startDate, endDate, null, "[]")) {
          countInRange++;
        }
      } else {
        // Check if lastUpdatedOn is today
        if (lastUpdatedOn.isSame(today, "day")) {
          countToday++;
        }
      }

      // Check if lastUpdatedOn is within the last 4 hours
      if (lastUpdatedOn.isAfter(fourHoursAgo)) {
        countLast4Hours++;
      }
    });
    console.log(countInRange);
    if (startDate && endDate) {
      return { countInRange, countLast4Hours };
    } else {
      return { countToday, countLast4Hours };
    }
  }
  const reset = () => {
    setLogsDataDateRange([null, null]);
    setSelectedRange([null, null]);
  };

  const getDataForBeds = async () => {
    setLoading(true);
    try {
      const url = process.env.REACT_APP_BASE_API + `/bed-roster-logs`;
      // const url = process.env.PUBLIC_URL + "/dummyDataBedRosterLogs.json";
      const result = await axios
        .get(url, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          const updatedArray = res.data.map((obj, index) => {
            return {
              ...obj,
              id: index + 1,
              max_last_update_timestamp: obj.max_last_update_timestamp,
            };
          });
          const startDate = logsDataDateRange[0];
          const endDate = logsDataDateRange[1];

          const filteredArray = updatedArray.filter((obj) => {
            const currentDate = dayjs(obj.max_last_update_timestamp);
            return currentDate >= startDate && currentDate <= endDate;
          });
          setData(filteredArray.length > 1 ? filteredArray : updatedArray);

          // Function to calculate total updates per day and update frequency
          function calculateUpdateFrequency(arr) {
            const updatesPerDay = {}; // To store total updates per day
            const uniqueDates = new Set(); // To store unique dates

            // Iterate over each object in the data array
            arr.forEach((obj) => {
              const date = obj.lastupdatedon;

              // Increment total updates for the day
              updatesPerDay[date] =
                (updatesPerDay[date] || 0) + obj.number_of_updates_by_day;

              // Add date to the set of unique dates
              uniqueDates.add(date);
            });

            // Calculate total updates across all days
            const totalUpdates = Object.values(updatesPerDay).reduce(
              (acc, val) => acc + val,
              0
            );

            // Calculate update frequency
            const updateFrequency = totalUpdates / uniqueDates.size;

            return updateFrequency;
          }

          // Call the function with your data
          const updateFrequency = calculateUpdateFrequency(
            filteredArray.length > 1 ? filteredArray : updatedArray
          );

          setUpdateFrequencyMetrics(Math.round(updateFrequency));

          function formatTimeInCST(hour) {
            const time = hour >= 12 ? "PM" : "AM";
            const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
            return `${formattedHour
              .toString()
              .padStart(2, "00")}:00 ${time} - ${(formattedHour + 1)
              .toString()
              .padStart(2, "00")}:00 ${time} CDT`;
          }
          // Function to find peak update time
          function findPeakUpdateTime(arr) {
            const updatesPerHour = {}; // To store total updates per hour

            // Iterate over each object in the filtered data array
            arr.forEach((obj) => {
              const hour = obj.last_update_by_hour;

              // Increment total updates for the hour
              updatesPerHour[hour] = Math.max(
                updatesPerHour[hour] || 0,
                obj.number_of_updates_by_hour
              );
            });

            // Find the hour with the maximum number of updates
            let peakUpdateTime = null;
            let maxUpdates = 0;

            for (const hour in updatesPerHour) {
              if (updatesPerHour[hour] > maxUpdates) {
                peakUpdateTime = hour;
                maxUpdates = updatesPerHour[hour];
              }
            }

            return { peakUpdateTime, maxUpdates };
          }

          // Call the function with your filtered data
          const { peakUpdateTime, maxUpdates } = findPeakUpdateTime(
            filteredArray.length > 1 ? filteredArray : updatedArray
          );

          setPeakUpdateTimeMetrics(
            formatTimeInCST(peakUpdateTime)
            // + " (Updates: " + maxUpdates + ")"
          );

          function findDayWithMostUpdates(arr) {
            const updatesPerDayOfWeek = {}; // To store total updates per day of the week

            // Iterate over each object in the filtered data array
            arr.forEach((obj) => {
              const dayOfWeek = obj.weekday_of_lastupdate;

              // Increment total updates for the day of the week
              updatesPerDayOfWeek[dayOfWeek] =
                (updatesPerDayOfWeek[dayOfWeek] || 0) +
                obj.number_of_updates_by_day_wk;
            });

            // Find the day of the week with the maximum number of updates
            let maxUpdatesDay = 0;
            let dayWithMostUpdates = "";

            for (const dayOfWeek in updatesPerDayOfWeek) {
              if (updatesPerDayOfWeek[dayOfWeek] > maxUpdatesDay) {
                maxUpdatesDay = updatesPerDayOfWeek[dayOfWeek];
                dayWithMostUpdates = dayOfWeek;
              }
            }

            return { dayWithMostUpdates, maxUpdatesDay };
          }

          // Call the function with your filtered data
          const { dayWithMostUpdates, maxUpdatesDay } = findDayWithMostUpdates(
            filteredArray.length > 1 ? filteredArray : updatedArray
          );

          setUpdatePatternMetrics(`${dayWithMostUpdates} `);

          const reducedArray = (
            filteredArray.length > 1 ? filteredArray : updatedArray
          ).reduce((acc, obj) => {
            const existingRecord = acc.find(
              (item) => item.facilityname === obj.facilityname
            );
            if (
              !existingRecord ||
              dayjs(obj.lastupdatedon) > dayjs(existingRecord.lastupdatedon)
            ) {
              // If no existing record for this facility or if the current record is more recent, replace it
              acc = acc.filter(
                (item) => item.facilityname !== obj.facilityname
              ); // Remove existing record
              acc.push(obj); // Add current record
            }
            return acc;
          }, []);

          const reducedArrayOriginal = updatedArray.reduce((acc, obj) => {
            const existingRecord = acc.find(
              (item) => item.facilityname === obj.facilityname
            );
            if (
              !existingRecord ||
              dayjs(obj.lastupdatedon) > dayjs(existingRecord.lastupdatedon)
            ) {
              // If no existing record for this facility or if the current record is more recent, replace it
              acc = acc.filter(
                (item) => item.facilityname !== obj.facilityname
              ); // Remove existing record
              acc.push(obj); // Add current record
            }
            return acc;
          }, []);

          setTableData(reducedArray);
          setTotalFacilities(reducedArrayOriginal.length);
          const { countToday, countInRange, countLast4Hours } =
            countUpdatedRecords(reducedArray, selectedRange);
          setUpdatedToday(filteredArray.length > 1 ? countInRange : countToday);
          setUpdatedLast4hours(countLast4Hours);
        });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  // Columns configuration
  const columns = [
    { field: "facilityname", headerName: "Hospital Name", width: 300 },
    {
      field: "max_last_update_timestamp",
      headerName: "Last Updated On",
      width: 300,
      renderCell: (params) => {
        return dayjs(params.value)
          .locale("en")
          .tz("America/Chicago")
          .format("ddd, DD MMM YYYY HH:mm:ss z");
      },
    },
    { field: "last_updated_by", headerName: "Last Updated By", width: 200 },
  ];
  const handleGroupAuth = (str) => {
    return userGroups && userGroups.indexOf(str) !== -1;
  };

  if (handleGroupAuth("IDPH_IA_BED_ROSTER_LOGS_DHHS_USERS")) {
    return (
      <div className="mt-5 pt-2">
        <Paper
          elevation={0}
          sx={{ paddingBlock: 2, display: "flex", alignItems: "center" }}
        >
          <List>
            <ListItem className={classes.root}>
              <ListItemIcon>
                <Avatar variant="rounded" sx={{ bgcolor: "#2F5E8B" }}>
                  <ReceiptLongOutlined
                    fontSize="small"
                    className={classes.icon}
                  />
                </Avatar>
              </ListItemIcon>
              <ListItemText className={classes.text} disableTypography>
                Bed Roster Logs
              </ListItemText>
              <Box sx={{ marginX: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    sx={{
                      ".css-1b6m7bd": {
                        width: "280px",
                      },
                    }}
                    slots={{ field: SingleInputDateRangeField }}
                    slotProps={{
                      textField: {
                        size: "small",
                        InputProps: { endAdornment: <CalendarMonth /> },
                      },
                      shortcuts: {
                        items: shortcutsItems,
                      },
                      actionBar: { actions: [] },
                    }}
                    name="allowedRange"
                    calendars={2}
                    // maxDate={dayjs("04/26/2024")}
                    value={selectedRange}
                    onChange={handleDateRangeChange}
                  />
                </LocalizationProvider>
              </Box>
              <Button onClick={() => reset()} variant="outlined" size="small">
                {" "}
                reset{" "}
              </Button>
            </ListItem>
          </List>
        </Paper>
        {!loading ? (
          <>
            <CardChart2
              updatedToday={updatedToday || 0}
              updatedLast4hours={updatedLast4hours || 0}
              notUpdatedToday={(totalFacilities || 0) - (updatedToday || 0)}
              notUpdatedLast4hours={
                (totalFacilities || 0) - (updatedLast4hours || 0)
              }
              selectedDateRange={selectedRange}
              chartData={data}
            />
            <CardChart
              updateFrequency={updateFrequencyMetrics}
              peakUpdateTime={peakUpdateTimeMetrics}
              peakUpdateDay={updatePatternMetrics}
              chartData={data}
            />
          </>
        ) : (
          <LinearProgress />
        )}

        <Paper
          sx={{ p: 2, mt: 1, borderRadius: "10px", backgroundColor: "white" }}
          elevation={2}
        >
          {" "}
          <Box sx={{ height: "65vh" }}>
            <DataGridPro
              sx={{
                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                  {
                    "margin-top": "1em",
                    "margin-bottom": "1em",
                  },
              }}
              rows={tableData}
              columns={columns}
              disableSelectionOnClick
              isRowSelectable={false}
              slots={{ toolbar: GridToolbar, loadingOverlay: LinearProgress }}
              slotProps={{
                toolbar: { printOptions: { disableToolbarButton: true } },
              }}
              density="compact"
              pagination
              loading={loading}
            />
          </Box>
        </Paper>
      </div>
    );
  } else {
    return <NotFound />;
  }
};

export default BedRosterLogBook;
