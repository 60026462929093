import React from "react";
import { Typography, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",

    border: "1px solid #dadde9",

    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
}));

export default function HospitalInfoTooltip({ row }) {
  return (
    <HtmlTooltip
      title={
        <>
          <Typography variant="subtitle2" color="inherit" padding={1}>
            {" "}
            HOSPITAL INFORMATION:{" "}
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Facility Name </TableCell>
                <TableCell>{row.row.original["HOSPITAL_NAME"]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>
                  {row.row.original["HQ_ADDRESS1"]},{" "}
                  {row.row.original["HQ_CITY"]}, {row.row.original["HQ_STATE"]},{" "}
                  {row.row.original["HQ_ZIP_CODE"]}{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>County</TableCell>
                <TableCell>{row.row.original["COUNTY_NAME"]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>RMCC Region</TableCell>
                <TableCell>{row.row.original["RMCC"]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>HCC Region </TableCell>
                <TableCell>{row.row.original["HCC"]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>AHA Id</TableCell>
                <TableCell> {row.row.original["Facility_AHA_ID"]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Provider Transfer Contact</TableCell>
                <TableCell>
                  {" "}
                  {row.row.original["PHONE_TRANSFER_CONTACT"]}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone Main</TableCell>
                <TableCell>{row.row.original["PHONE_MAIN"]}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      }
    >
      <strong>{row.row.original["HOSPITAL_NAME"]}</strong>
    </HtmlTooltip>
  );
}