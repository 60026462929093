import React, { useState, useEffect, useRef } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import {
  GridRowModes,
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
  GridEditInputCell,
  // GridToolbarContainer,
  // GridToolbarColumnsButton,
  // GridToolbarFilterButton,
  // GridToolbarDensitySelector,
  useGridApiContext,
  GridEditSingleSelectCell,
  // GridToolbarExport,
} from "@mui/x-data-grid-pro";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { randomId } from "@mui/x-data-grid-generator";
import { makeStyles } from "@mui/styles";

import {
  FormControl,
  Typography,
  ListItem,
  ListItemText,
  TextField,
  Chip,
  AlertTitle,
  DialogContent,
  DialogContentText,
  LinearProgress,
  InputLabel,
  Select,
  ListSubheader,
  InputAdornment,
  Divider,
  Paper,
  Snackbar,
  Fade,
  Avatar,
} from "@mui/material";
import { listOfFacilities } from "../../services/Constants";
import { MenuItem, List } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import axios from "axios";
import {
  Flag,
  Person,
  PersonOff,
  Refresh,
  Search,
  SyncAlt,
} from "@mui/icons-material";
import NotFound from "../../Alerts/NotFound";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Papa from "papaparse";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import locale if needed
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import alertGif from "../../../../public/wired-lineal-1140-error.gif";
import BedsDetailedActivityButton from "./BedsDetailledActivity";
import AttestationButton from "../../services/Attestation";
// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarColumnsButton />
//       <GridToolbarFilterButton />
//       <GridToolbarDensitySelector />
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("en");

// Set default timezone to CST
dayjs.tz.setDefault("America/Chicago");

const useStyles = makeStyles({
  textField: {
    border: "none",
  },
  root: {
    height: "20px",
    color: "black",
    display: "flex",
  },
  text: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#212121",
  },
  icon: {
    color: "white",
  },
});
const StyledBox = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  "& .MuiDataGrid-cell--editable": {
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === "dark" ? 0 : 0.1
    })`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
  marginBlock: "5px",
}));

function validateName(username) {
  let promiseTimeout;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      // console.log(username);

      const exists = username.toString();
      // console.log(exists);
      resolve(
        exists.length > 50
          ? `${username} : This field can't be more than 50 characters!`
          : exists
          ? null
          : "This field cannot be empty!"
      );
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}

export default function FullFeaturedCrudGrid() {
  const classes = useStyles();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [attestationEndpoint, setAttestationEndpoint] = useState("");
  const { authState, oktaAuth } = useOktaAuth();
  const [openInfo, setOpenInfo] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [userGroups, setUserGroups] = useState(null);
  const [facilityNames, setFacilityNames] = useState(null);
  const [facilityCode, setFacilityCode] = useState(null);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [visibleRowCount, setVisibleRowCount] = useState(0);
  const [userFullName, setUserFullName] = useState("");
  const [loading, setLoading] = useState(null);
  const accessTokenResponse = authState.accessToken;
  const bearerToken = accessTokenResponse?.accessToken;
  const [jsonData, setJsonData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFacilities, setFilteredFacilities] = useState([]);
  const [notYetClassifiedToTrue, setNotYetClassifiedToTrue] = useState(false);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        setUserGroups(info.groups);
        setFacilityNames(info.facilityAdmins);
        setUserFullName(info.name);
        // setUserGroupInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);
  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredFacilities(
      filterByValue(listOfFacilities, "facility_code", facilityNames).filter(
        (facility) => facility.facility_name.toLowerCase().startsWith(query)
      )
    );
  };

  const getDataForBeds = async (selectedFacility) => {
    setRows([]);
    setNotYetClassifiedToTrue(false);
    setLoading(true);
    setFacilityCode(selectedFacility.target.value);

    try {
      const url =
        process.env.REACT_APP_BASE_API +
        `/bed-roster/${selectedFacility.target.value}`;
      // const url = "sampleRoster.json"
      const result = await axios
        .get(url, {
          headers: {
            Authorization: bearerToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const updatedArray = res.data.map((obj) => {
            if (obj.bedId) {
              return {
                ...obj,
                id: obj.bedId,
                staffed:
                  obj.staffed === 0
                    ? "Non-staffed"
                    : obj.staffed === 1
                    ? "Staffed"
                    : "",
                active: obj.active === 0 ? "Inactive" : "Active",
                lastUpdatedOn: dayjs(obj.lastUpdatedOn)
                  .locale("en")
                  .tz("America/Chicago")
                  .format("ddd, DD MMM YYYY HH:mm:ss z"),
                createdOn: dayjs(obj.createdOn)
                  .locale("en")
                  .tz("America/Chicago")
                  .format("ddd, DD MMM YYYY HH:mm:ss z"),
              };
            } else {
              return obj;
            }
          });
          function sortArray(data) {
            // Separate the objects based on bedType
            const notClassified = data.filter(
              (item) => item.bedType === "Not Yet Classified"
            );
            const others = data.filter(
              (item) => item.bedType !== "Not Yet Classified"
            );
            // Concatenate the two arrays, with "notClassified" first
            return notClassified.concat(others);
          }
          console.log(updatedArray);
          setRows(sortArray(updatedArray));
          setVisibleRowCount(updatedArray.length);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleStateChange = (e) => {
    if (e && e.filter && e.filter.visibleRowsLookup) {
      const countTrueValues = (obj) =>
        Object.values(obj).filter(Boolean).length;
      const trueCount = countTrueValues(e.filter.visibleRowsLookup);
      setVisibleRowCount(trueCount);
    } else {
      // Handle the case where e.filter.visibleRowsLookup is null or undefined
      // You can choose to do nothing or provide a default value or error handling as needed.
    }
  };

  const postNewRecord = async (data) => {
    // console.log(data);
    const calculateStaffed = (activeStatus, providedStaffed) => {
      // If active is Inactive or 0, set staffed to Non-staffed, else use the provided value
      return activeStatus === "Inactive" || activeStatus === 0
        ? 0
        : providedStaffed;
    };

    const payload = [
      {
        facilityName: data.facilityName,
        roomNumber: data.roomNumber,
        bedType: data.bedType,
        bedNumber: data.bedNumber,
        lastUpdatedBy: userFullName,
        createdBy: userFullName,
        lastUpdatedOn: Math.floor(
          (new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000) /
            1000
        ),
        active: data.active === "Active" ? 1 : 0,
        isNew: 1,
        email: data.email,
        createdOn: Math.floor(
          (new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000) /
            1000
        ),
        staffed: calculateStaffed(
          data.active,
          data.staffed === "Non-staffed"
            ? 0
            : data.staffed === "Staffed"
            ? 1
            : null
        ),
      },
    ];

    try {
      const url =
        process.env.REACT_APP_BASE_API + `/bed-roster/${facilityCode}`;
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/json",
        },
      });
      console.log(response && response);
      const updatedArray =
        response &&
        response.data.records.map((obj) => {
          if (obj.bedId) {
            return { ...obj, id: obj.bedId };
          } else {
            return obj;
          }
        });
      // setRows(updatedArray);
      // console.log(response);
    } catch (e) {
      console.log(e);
    }
    setOpenInfo(true);
  };
  const modifyExistingRecord = async (data) => {
    // console.log(data);
    const calculateStaffed = (activeStatus, providedStaffed) => {
      // If active is Inactive or 0, set staffed to Non-staffed, else use the provided value
      return activeStatus === "Inactive" || activeStatus === 0
        ? 0
        : providedStaffed;
    };

    const payload = {
      bedId: data.bedId,
      facilityName: data.facilityName,
      roomNumber: data.roomNumber,
      bedType: data.bedType,
      bedNumber: data.bedNumber,
      lastUpdatedBy: userFullName,
      // createdBy: data.createdBy,
      lastUpdatedOn: Math.floor(
        (new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000) /
          1000
      ),
      active: data.active === "Active" ? 1 : 0,
      // isNew: 0,
      email: data.email,
      staffed: calculateStaffed(
        data.active,
        data.staffed === "Non-staffed"
          ? 0
          : data.staffed === "Staffed"
          ? 1
          : null
      ),
      // createdOn: data.createdOn,
    };

    try {
      const url =
        process.env.REACT_APP_BASE_API + `/bed-roster/${facilityCode}`;
      // const url = "IA_Bed_Occupancy.json"
      const response = await axios.put(url, payload, {
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/json",
        },
      });
      // console.log(response);
    } catch (e) {
      console.log(e);
    }
    setOpenInfo(true);
  };

  const deleteExistingRecord = async (data) => {
    // console.log("delete", data);
    const payload = {
      bedId: data.bedId,
    };

    try {
      const url =
        process.env.REACT_APP_BASE_API + `/bed-roster/${facilityCode}`;
      // const url = "IA_Bed_Occupancy.json"
      const response = await axios.delete(url, {
        data: payload,
        headers: {
          Authorization: bearerToken,
          "Content-Type": "application/json",
        },
      });
      // console.log(response);
    } catch (e) {
      console.log(e);
    }
    setOpenInfo(true);
  };
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    // console.log("delete");
    setRecordToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    const rowToBeDeletd = rows.filter((row) => row.id == recordToDelete);
    const deletedRow = rowToBeDeletd[0];
    deleteExistingRecord(deletedRow);
    setRows(rows.filter((row) => row.id !== recordToDelete));
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteCancelled = () => {
    setRecordToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleAddRowClick = () => {
    const id = randomId();
    setRows((oldRows) => {
      // const facilityN = oldRows[0].facilityName.toUpperCase();
      const name = filterByValue(
        listOfFacilities,
        "facility_code",
        facilityCode
      );
      console.log(name[0].facility_name);
      return [
        {
          id,
          staffed: "",
          roomNumber: "",
          bedType: "",
          bedNumber: "",
          facilityName: name[0].facility_name,
          lastUpdatedBy: userFullName,
          createdBy: userFullName,
          lastUpdatedOn: dayjs(),
          isNewRecord: true,
          active: null,
          email: userInfo.email,
          createdOn: dayjs(),
        },
        ...oldRows,
      ];
    });
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "roomNumber" },
    }));
  };
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    // console.log(editedRow);
    if (editedRow.isNewRecord) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  function CustomStatusEditComponent(props) {
    const apiRef = useGridApiContext();

    const handleValueChange = async () => {
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: "staffed",
        value: "Non-staffed",
      });
    };

    return (
      <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
    );
  }

  function CustomTypeEditComponent(props) {
    const apiRef = useGridApiContext();

    const handleValueChange = (event) => {
      console.log(props, "handleChange");
      console.log(event.target.value);
      if (
        event.target.value === "Rehab" ||
        event.target.value === "Surgical/Procedure" ||
        event.target.value === "Unclassified"
      ) {
        apiRef.current.setEditCellValue({
          id: props.id,
          field: "active",
          value: "Inactive",
        });
        apiRef.current.setEditCellValue({
          id: props.id,
          field: "staffed",
          value: "Non-staffed",
        });
      } else {
        apiRef.current.setEditCellValue({
          id: props.id,
          field: "active",
          value: "Active",
        });
        apiRef.current.setEditCellValue({
          id: props.id,
          field: "staffed",
          value: "Non-staffed",
        });
      }
    };
    return (
      <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
    );
  }

  const processRowUpdate = (newRow) => {
    const name = filterByValue(listOfFacilities, "facility_code", facilityCode);
    const currentTimeStamp = new Date();
    const updatedRow = {
      ...newRow,
      facilityName: name[0].facility_name,
      lastUpdatedBy: userInfo.given_name,
      lastUpdatedOn: currentTimeStamp.toJSON(),
      email: userInfo.email,
    };
    const requiredFields = ["bedNumber", "roomNumber", "bedType"];
    const isValid =
      requiredFields.every((field) => newRow[field]) &&
      newRow["bedType"] !== "Not Yet Classified";
    console.log(isValid, "isValid");
    console.log(newRow["bedType"] === "Not Yet Classified");
    const checkIfSameFields = ["bedNumber", "roomNumber", "bedType"];
    let count = 0;
    rows.forEach((row) => {
      if (checkIfSameFields.every((field) => newRow[field] === row[field])) {
        count++;
      }
    });
    const isSame = count > 1;
    const isSameNew = count >= 1;
    console.log(count);

    setOpen(!isValid);
    if (isValid) {
      setOpen(false);
      if (newRow.isNewRecord) {
        if (!isSameNew) {
          setOpen(false);
          postNewRecord(updatedRow);
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          return updatedRow;
        } else alert("duplicate");
      } else {
        if (!isSame) {
          setOpen(false);
          const updatedExistingRow = { ...updatedRow, bedId: newRow.bedId };
          modifyExistingRecord(updatedExistingRow);
          setRows(
            rows.map((row) => (row.id === newRow.id ? updatedExistingRow : row))
          );
          return updatedExistingRow;
        } else alert("duplicate");
      }
    }
  };
  const handleAttestationConfirm = () => {
    console.log("d");
    setOpenInfo(true);
  };

  const handleFileChange = (event) => {
    const validKeys = [
      "id",
      "Created On",
      "Admin Email",
      "Hospital Name",
      "Room Number",
      "Bed Type",
      "Bed Number",
      "Updated By",
      "Added By",
      "Updated On",
      "Bed Status",
      "Staffed",
    ];
    const requiredKeys = new Set(validKeys);

    function isValidRow(row) {
      const rowKeys = Object.keys(row);
      for (const key of rowKeys) {
        if (!requiredKeys.has(key)) {
          return false;
        }
      }
      return true;
    }

    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      complete: (result) => {
        const [header, ...data] = result.data;
        const isValid = result.data.every(isValidRow);
        if (isValid) {
          // Proceed with processing the valid data
          const changeDataStruct = result.data.map((data) => ({
            facilityName: data["Hospital Name"],
            roomNumber: data["Room Number"],
            bedType: data["Bed Type"],
            bedNumber: data["Bed Number"],
            lastUpdatedBy: data["Updated By"],
            createdBy: data["Added By"],
            lastUpdatedOn: data["Updated On"],
            active: data["Bed Status"] === "Active" ? 1 : 0,
            isNew: 1,
            email: data["Admin Email"],
            createdOn: data["Created On"],
            staffed:
              data["Staffed"] === "Non-staffed"
                ? 0
                : data["Staffed"] === "Staffed"
                ? 1
                : null,
          }));
          setJsonData(changeDataStruct);
        } else {
          // Display error message
          console.error("Uploaded CSV file contains invalid rows.");
          // You can display an error message to the user here
        }
      },
      header: true,
    });

    JSON.stringify(jsonData, null, 2);
  };
  console.log(jsonData);
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "bedId",
      headerName: "id",
      width: 200,
      editable: false,
      hideable: true,
    },

    {
      field: "createdOn",
      headerName: "Created On",
      width: 200,
      editable: false,
      hideable: true,
    },
    {
      field: "email",
      headerName: "Admin Email",
      width: 200,
      editable: false,
      hideable: true,
    },
    {
      field: "facilityName",
      headerName: "Hospital Name",
      width: 250,
      editable: false,
    },
    {
      field: "roomNumber",
      headerName: "Room Number",
      width: 150,
      editable: true,
      preProcessEditCellProps,
      renderEditCell: renderEditName,
    },
    {
      field: "bedType",
      headerName: "Bed Type",
      width: 200,
      type: "singleSelect",
      valueOptions: [
        "Adult ICU Beds",
        "Adult Med Surg Beds",
        "ER Beds",
        "Labor Delivery Beds",
        "Neonatal Beds (NICU)",
        "Newborn Beds",
        "Overflow ER Beds",
        "Peds Beds",
        "Peds ICU Beds",
        "Psych Adult Beds",
        "Psych Child Beds",
        "Psych Geriatric Beds",
        "Rehab",
        "Surgical/Procedure",
        "SUD Beds",
      ],
      editable: true,
      renderEditCell: (params) => (
        <>
          <CustomTypeEditComponent {...params} />
        </>
      ),
      renderCell: (params) => {
        if (params.value === "Not Yet Classified") {
          setNotYetClassifiedToTrue(true);
          return (
            <Box sx={{ color: "red" }}>
              {params.value}
              <img
                src={alertGif}
                width="30px"
                height="30px"
                style={{ marginLeft: "5px" }}
              />
            </Box>
          );
        } else {
          return <Box>{params.value}</Box>;
        }
      },
      hideable: true,
    },
    {
      field: "bedNumber",
      headerName: "Bed Number",
      width: 150,
      editable: true,
      preProcessEditCellProps,
      renderEditCell: renderEditName,
    },
    {
      field: "lastUpdatedBy",
      headerName: "Updated By",
      width: 150,
      editable: false,
    },
    { field: "createdBy", headerName: "Added By", width: 150, editable: false },
    {
      field: "lastUpdatedOn",
      headerName: "Updated On",
      width: 150,
      editable: false,
    },
    {
      field: "active",
      headerName: "Bed Status",
      type: "singleSelect",
      valueOptions: ({ row }) => {
        if (!row) {
          return ["Active", "Inactive"];
        }
        return row.bedType === "Surgical/Procedure" ||
          row.bedType === "Rehab" ||
          row.bedType === "Unclassified"
          ? ["Inactive"]
          : ["Active", "Inactive"];
      },
      width: 120,
      editable: true,
      hideable: true,
      description:
        "Active indicates a bed that is licensed, physically set up, and available for use",
      renderEditCell: (params) => <CustomStatusEditComponent {...params} />,
      renderCell: (params) => {
        if (params.value === "Active") {
          return (
            <Box>
              <Chip
                size="small"
                icon={<CheckIcon />}
                color="success"
                variant="filled"
                label={params.value}
              ></Chip>
            </Box>
          );
        } else if (params.value === "Inactive") {
          return (
            <Box>
              <Chip
                size="small"
                icon={<CloseIcon />}
                variant="outlined"
                label={params.value}
              ></Chip>
            </Box>
          );
        }
      },
    },
    {
      field: "staffed",
      headerName: "Staffed",
      type: "singleSelect",
      valueOptions: ({ row }) => {
        if (!row) {
          return ["Staffed", "Non-staffed"];
        }
        return row.active === "Inactive" ||
          row.bedType === "Surgical/Procedure" ||
          row.bedType === "Rehab" ||
          row.bedType === "Unclassified"
          ? ["Non-staffed"]
          : ["Staffed", "Non-staffed"];
      },
      width: 150,
      editable: true,
      description:
        "Staffed indicates:  Beds that are licensed and physically available for which staff is on hand to attend to the patient who occupies the bed.",
      renderCell: (params) => {
        if (params.value === "Staffed") {
          return (
            <Box>
              <Chip
                size="small"
                icon={<Person />}
                color="success"
                variant="filled"
                label={params.value}
              ></Chip>
            </Box>
          );
        } else if (params.value === "Non-staffed") {
          return (
            <Box>
              <Chip
                size="small"
                icon={<PersonOff />}
                variant="outlined"
                label={params.value}
              ></Chip>
            </Box>
          );
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 120,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const filterByValue = (arr, key, values) => {
    return arr.filter((item) => (values ? values.includes(item[key]) : ""));
  };
  const handleGroupAuth = (str) => {
    return userGroups && userGroups.indexOf(str) !== -1;
  };
  if (handleGroupAuth("IDPH_IA_Bed_Roster_Admins")) {
    return (
      <>
        <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
          <DialogTitle>Are you sure you want to delete this?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Warning: Clicking 'Yes, Delete' will permanently remove the
              selected bed record.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDeleteCancelled}
              color="primary"
              variant="outlined"
            >
              No, Cancel
            </Button>
            <Button
              onClick={handleDeleteConfirmed}
              color="error"
              variant="contained"
            >
              Yes, Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="mt-3 pt-5">
          <Paper elevation={0} sx={{ paddingBlock: 2, marginBottom: 1 }}>
            <List>
              <ListItem className={classes.root}>
                <ListItemIcon>
                  <Avatar variant="rounded" sx={{ bgcolor: "#2F5E8B" }}>
                    <SyncAlt fontSize="small" className={classes.icon} />
                  </Avatar>
                </ListItemIcon>
                <ListItemText className={classes.text} disableTypography>
                  Bed Roster
                </ListItemText>
              </ListItem>
            </List>
          </Paper>
          {/* <div className="alert alert-light">
            Authorized users agree information contained within the CyncHealth's
            Dashboards is confidential and agree to not disseminate such
            information contained therein.
          </div> */}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={openInfo}
            autoHideDuration={6000}
            onClose={() => {
              setOpenInfo(false);
            }}
            key={"top" + "right"}
            TransitionComponent={Fade}
          >
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenInfo(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              variant="filled"
              severity="info"
            >
              <AlertTitle>Important Information</AlertTitle>
              The changes you have made to the Roster will reflect on the Bed
              Capacity Dashboard in
              <strong> 30 minutes!</strong>
            </Alert>
          </Snackbar>
          {/* <Collapse in={openInfo}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenInfo(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              style={{ marginBlock: "8px", backgroundColor: "white" }}
              severity="info"
              variant="outlined"
            >
              <AlertTitle>Important Information</AlertTitle>
              The changes you have made to the Roster will reflect on the Bed
              Capacity Dashboard in
              <strong> 30 minutes!</strong>
            </Alert>
          </Collapse> */}
        </div>
        <Box
          style={{
            height: "70vh",
            width: "100%",
          }}
        >
          <Paper elevation={0} sx={{ padding: 2 }}>
            <FormControl>
              <InputLabel size="small" id="demo-simple-select-helper-label">
                Please select the hospital
              </InputLabel>

              <Select
                sx={{ backgroundColor: "white", width: "40vw" }}
                size="small"
                select
                onChange={getDataForBeds}
                value={facilityCode}
                required
                defaultValue={facilityNames ? facilityNames[0] : ""}
                label="Please select the hospital"
                MenuProps={{
                  autoFocus: false,
                  style: {
                    maxHeight: 600,
                  },
                }}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search hospital"
                    fullWidth
                    autoFocus
                    onKeyDown={(event) => event.stopPropagation()}
                  />
                </ListSubheader>

                {filteredFacilities.length < 1
                  ? userInfo &&
                    filterByValue(
                      listOfFacilities,
                      "facility_code",
                      facilityNames
                    )
                      .sort((a, b) =>
                        a.facility_name.localeCompare(b.facility_name)
                      ) // Sort alphabetically
                      .map((facility) => (
                        <MenuItem
                          key={facility.facility_code}
                          value={facility.facility_code}
                        >
                          {facility.facility_name}
                        </MenuItem>
                      ))
                  : filteredFacilities
                      .sort((a, b) =>
                        a.facility_name.localeCompare(b.facility_name)
                      ) // Sort alphabetically
                      .map((facility) => (
                        <MenuItem
                          key={facility.facility_code}
                          value={facility.facility_code}
                        >
                          {facility.facility_name}
                        </MenuItem>
                      ))}
              </Select>
            </FormControl>
            <Chip
              size="small"
              style={{ margin: "7px" }}
              label={"Beds Found: " + visibleRowCount}
            ></Chip>
            {rows.length > 1 ? (
              <Box sx={{ float: "right" }}>
                <BedsDetailedActivityButton
                  selectedFacilityCode={facilityCode}
                />
                <AttestationButton
                  apiEndpoint={
                    process.env.REACT_APP_BASE_API +
                    `/bed-roster-bulk-update/${facilityCode}`
                  }
                  payload={{
                    lastUpdatedBy: userFullName,
                    lastUpdatedOn: Math.floor(
                      (new Date().getTime() -
                        new Date().getTimezoneOffset() * 60 * 1000) /
                        1000
                    ),
                  }}
                  dialogMessage={null}
                  onConfirm={handleAttestationConfirm}
                />
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={handleAddRowClick}
                >
                  Add new bed
                </Button>
              </Box>
            ) : null}
          </Paper>
          {notYetClassifiedToTrue ? (
            <Alert
              size="small"
              severity="warning"
              variant="outlined"
              style={{ marginBlock: "8px", backgroundColor: "#fff7e1" }}
            >
              Entries in RED reflect beds that have not been assigned and may
              impact the accuracy of the dashboard.
            </Alert>
          ) : (
            ""
          )}
          {/* <input
        type="file"
        accept=".csv"
        onChange={(event) => handleFileChange(event)}
       
        id="csv-upload"
      /> */}
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity="error"
              sx={{ mb: 1, mt: 1 }}
            >
              Room Number, Bed Type and Bed Number fields cannot be left empty!!
            </Alert>
          </Collapse>
          {rows.length > 1 ? (
            <StyledBox>
              <DataGridPro
                sx={{
                  boxShadow: 2,
                  border: 1,
                  padding: 1,
                  backgroundColor: "white",
                  borderColor: "lightgrey",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                    fontWeight: "bold",
                  },
                  "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                    {
                      marginTop: "1em",
                      marginBottom: "1em",
                    },
                  "& .MuiDataGrid-row--editing": {
                    boxShadow: "4px 1px 5px 0px rgba(0,0,0,0.12)",
                  },
                }}
                rowHeight={38}
                // pagination
                rows={rows}
                slots={{
                  toolbar: GridToolbar,
                  loadingOverlay: LinearProgress,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { allColumns: true },
                    printOptions: { disableToolbarButton: true },
                  },
                }}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                onStateChange={handleStateChange}
                loading={loading}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                  },
                  columns: {
                    columnVisibilityModel: {
                
                      createdBy: false,
                    
                      bedId: false,
                      createdOn: false,
                      email: false,

                      isNew: false,
                    },
                  },
                }}
              />
            </StyledBox>
          ) : (
            ""
          )}
          {facilityCode == null ? (
            <Box
              style={{
                marginTop: "10px",
                backgroundColor: "white",
                height: "60vh",
                textAlign: "center",
                border: "1px",
                borderRadius: "5px",
              }}
            >
              <Typography paddingTop={25}>
                Select the facility to load the data.
              </Typography>
              <Refresh />
            </Box>
          ) : facilityCode !== null && rows.length < 1 ? (
            <Box
              style={{
                marginTop: "10px",
                backgroundColor: "white",
                height: "60vh",
                textAlign: "center",
                border: "1px",
                borderRadius: "5px",
              }}
            >
              {loading ? (
                <LinearProgress />
              ) : (
                <>
                  <Typography paddingTop={25}>
                    No Data! Please select other facility.
                  </Typography>
                  <Refresh />
                </>
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
      </>
    );
  } else {
    return <NotFound />;
  }
}
