import { useOktaAuth } from "@okta/okta-react";
import { useState, useEffect } from "react";

const useGroupAuth = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        setUserGroups(info.groups || []);
      });
    } else {
      setUserGroups([]);
    }
  }, [authState, oktaAuth]);

  const checkUserGroup = (groupName) => {
    return userGroups.includes(groupName);
  };

  return checkUserGroup;
};

export default useGroupAuth;